<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="row left-align vert-offset-top-1">
        <div class="col s12 m8 offset-m2 l6 offset-l3">

            <div v-if="mustRefreshCountries" class="col s12 no-padding">
                <div class="col s12">
                    <div class="col s6 m3 l2 offset-s3 offset-m6 offset-l5 vert-offset-top-3">
                        <button v-if="!isProcessing"
                                class="btn btn-block homeboy-gold-text homeboy-charcoal-black"
                                @click.prevent="refreshCountries()">
                            Reload
                        </button>
                    </div>
                </div>

            </div>

            <div v-if="!mustRefreshCountries" class="card col s12 vert-offset-top-1 padding-top-half">

                <div class="col s12 no-padding">
                    <div class="col s12 card-title left-align padding-top-half padding-bottom-half border-bottom-1 grey-border border-lighten-2 homeboy-charcoal-black-text homeboy-font align-middle">
                        Login as a HomeBoy Supporter
                    </div>
                    <div class="card-content col s12 padding-left-1 padding-right-1 padding-top-1">

                        <form @submit.prevent="customerLogin">

                            <div class="row vert-offset-bottom-half">

                                <div class="row col s12 vert-offset-top-1">
                                    <custom-select-component ref="country" v-model="selected_country" :options="countries" title="Type to select your Country"></custom-select-component>
                                </div>
                                <div class="row col s12 vert-offset-bottom-half">
                                    <input-component id="mobile" type="tel" v-model="mobile" label="Mobile Number" icon="phone" required="required" minlength="6" maxlength="15"></input-component>
                                </div>


                            </div>

                            <div class="row vert-offset-bottom-half">

                                <div class="col s12 vert-offset-bottom-0">
                                    <input-component v-model="pin" type="password" id="pin" label="PIN" icon="lock" required="required"></input-component>
                                </div>
                            </div>

                            <div class="row vert-offset-bottom-0">
                                <div class="col s12">
                                    <div class="col s8 l6 left-align">
                                        <check-box-component  id="remember" v-model="remember" label="Remember Me"></check-box-component>
                                    </div>
                                    <div class="col s4 l6 right-align">
                                        <button type="submit" :disabled="!isValidForm"
                                                class="btn homeboy-gold-text homeboy-charcoal-black">
                                            Login
                                        </button>
                                    </div>
                                </div>

                            </div>


                            <div class="col s12 m12 l12 vert-offset-top-2 left-align border-top-1 grey-border border-lighten-2 font-12 padding-top-1 padding-left-0 padding-right-0">
                                <div class="col s12 no-padding vert-offset-top-half">
                                    <span class="black-text font-12">Forgot your PIN?</span>
                                    <router-link
                                            class="homeboy-gold-text font-12 font-bold vert-offset-left-half"
                                            to="/pin/reset/request">
                                        <u>Click here to Reset your security PIN</u>
                                    </router-link>
                                </div>
                                <div class="col s12 no-padding vert-offset-top-half">
                                    <span class="black-text font-12">Do you need to verify your account?</span>
                                    <router-link
                                            class="homeboy-gold-text font-12 font-bold vert-offset-left-half"
                                            to="/send/otp"
                                    >
                                        <u>Click here to Verify your account</u>
                                    </router-link>
                                </div>
                                <div class="col s12 no-padding vert-offset-top-half">
                                    <span class="black-text font-12">Do you need to transact as a HomeBoy?</span>
                                    <router-link
                                            class="homeboy-gold-text font-12 font-bold vert-offset-left-half"
                                            to="/transact"
                                    >
                                        <u>Click here to login as a HomeBoy</u>
                                    </router-link>
                                </div>

                                <div class="col s12 no-padding vert-offset-top-1 border-top-1 grey-border border-lighten-2 center">
                                    <div class="black-text font-12 vert-offset-top-1">No Account yet?</div>
                                    <div class="col s12 vert-offset-top-half">
                                        <router-link
                                                class="homeboy-gold-text btn font-10 font-bold vert-offset-left-half homeboy-charcoal-black"
                                                to="/register"
                                        >
                                            <u>Sign Up to Support a HomeBoy</u>
                                        </router-link>
                                    </div>

                                </div>


                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

    import InputComponent from "@/components/materialize/InputComponent.vue";
    import CheckBoxComponent from "@/components/materialize/CheckBoxComponent.vue";
    import CustomSelectComponent from "@/components/materialize/CustomSelectComponent.vue";

    import {mapState} from "vuex"


    export default {
        name: 'Customer-Login',
        components:{
            InputComponent,CheckBoxComponent,CustomSelectComponent
        },
        data(){
            return{
                mobile:"",
                pin:"",
                remember:false,
                selected_country:"",
                obj_selected_country:null,
            }
        },
        created(){
            this.refreshCountries();
            this.selected_country = this.default_country;
            this.loadMenu();
            this.$setPageHeader(/*{
                title: "Find a HomeBoy",
                icon: "chevron_left",
                url: "/customer/home"
            }*/);

        },
        mounted(){
            console.log("LOGIN MOUNTED:");

           // this.$refs.dropme.initializeDropdown();
        },
        methods:{
            loadMenu: function()
            {
                let _arrMenu = [
                    {
                        title : "Find Supplier",
                        link: "/search/supplier",
                        icon:"search"
                    },

                ];
                this.$store.dispatch('COMMIT_SET_MENU_LINKS', _arrMenu);
            },
            customerLogin()
            {
                let _thisObj = this;
                const _formData = {
                    msisdn:_thisObj.mobile,
                    password:_thisObj.pin,
                    country:_thisObj.obj_selected_country.key,
                    source: _thisObj.$source
                };
                this.$store.dispatch('GET_CUSTOMER_AUTH_REQUEST', _formData).then(() => {
                    this.$store.dispatch('CUSTOMER_SET_COUNTRY', _thisObj.obj_selected_country);
                    this.$router.push('/customer/home');
                })
            },
            refreshCountries:function ()
            {
                console.log("GetCountries:");
                this.$store.dispatch("COUNTRY_GET_LIST_ACTIVE");
            },
            updateSelectedCountry: function(_value)
            {
                let _thisObj = this;
                if(!_thisObj.hasCountries || typeof _value === "undefined" || _value === null || _value.length <= 0 )
                {
                    _thisObj.obj_selected_country = null;
                    return;
                }

                let _arrFound = _thisObj.countries.filter(function(_item)
                {
                    return (_item.key === _thisObj.selected_country);
                });
                if(typeof _arrFound === "undefined" || _arrFound === null || _arrFound.length <= 0)
                {
                    _thisObj.obj_selected_country = null;
                    return;
                }

                _thisObj.obj_selected_country = _arrFound[0];
            }
        },
        computed:{
            ...mapState({
                countries:state=>state.countries.list_items_active,
                processing: state => state.processing,
                default_country: state => state.default_country,
                min_pin: state => state.customers.validation.pin.minLength,
                max_pin: state => state.customers.validation.pin.maxLength,
                min_msisdn: state => state.customers.validation.msisdn.minLength,
                max_msisdn: state => state.customers.validation.msisdn.maxLength,
            }),
            hasCountries: function()
            {
                return (typeof this.countries !== "undefined" && this.countries  !== null && this.countries.length > 0);
            },
            isProcessing: function()
            {
                return (typeof this.processing !== "undefined" && this.processing !== null && this.processing > 0);
            },
            mustRefreshCountries: function()
            {
                console.log("!this.isProcessing && !this.hasCountries:: ",!this.isProcessing && !this.hasCountries);
                return (!this.isProcessing && !this.hasCountries);
            },
            hasMobile: function()
            {
                return (typeof this.mobile !== "undefined" && this.mobile !== null && this.mobile.length > 0);
            },
            isPhoneNumber()
            {
                return this.hasMobile && this.mobile.isPhoneNumber();
            },
            hasPin: function()
            {
                return (this.pin !== "undefined" && this.pin !== null && this.pin.length > 0);
            },
            isValidPin: function()
            {
                return (this.hasPin && this.pin.length >= this.min_pin && this.pin.length <= this.max_pin);
            },
            isValidMobile: function()
            {
                return (this.isPhoneNumber && this.mobile.length >= this.min_msisdn && this.mobile.length <= this.max_msisdn);
            },
            isValidForm()
            {
                return (this.isValidMobile && this.isValidPin);
            }

        },
        watch: {
            selected_country: function(_value)
            {
                this.updateSelectedCountry(_value);
            },
            countries: function(_value)
            {
                if(typeof _value !== "undefined" && _value !== null && _value.length > 0)
                {
                    this.updateSelectedCountry(this.selected_country);
                }
            }
        }
    }
</script>
