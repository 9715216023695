<template>
  <label :for="id">
    <input type="checkbox" class="validate" :id="id" name="" :value="value" :required="required" />
    <span> {{ label }} </span>
  </label>
</template>

<script>

  import "materialize-css/dist/js/materialize.min"
  //import $ from 'jquery'
    //import M from "materialize-css";
  //import bus from "../../main.js";

    export default {
        name:"check-box-component",
        props: {
            label:
                {
                    type:[String,Number]
            },
            id:
                {
                type:[String,Number],
            },
            required:{
                type:String
            },
            name:{
                type:[String,Array],
                default:""
            },value:
                {

                }


        },

    };





</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  input:not([type]):focus:not([readonly]),
  input[type=text]:not(.browser-default):focus:not([readonly]),
  input[type=password]:not(.browser-default):focus:not([readonly]),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=url]:not(.browser-default):focus:not([readonly]),
  input[type=time]:not(.browser-default):focus:not([readonly]),
  input[type=date]:not(.browser-default):focus:not([readonly]),
  input[type=datetime]:not(.browser-default):focus:not([readonly]),
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
  input[type=tel]:not(.browser-default):focus:not([readonly]),
  input[type=number]:not(.browser-default):focus:not([readonly]),
  input[type=search]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #fbc02d;
    -webkit-box-shadow: 0 1px 0 0 #fbc02d;
    box-shadow: 0 1px 0 0 #fbc02d;
  }

  input:not([type]):focus:not([readonly]) + label,
  input[type=text]:not(.browser-default):focus:not([readonly]) + label,
  input[type=password]:not(.browser-default):focus:not([readonly]) + label,
  input[type=email]:not(.browser-default):focus:not([readonly]) + label,
  input[type=url]:not(.browser-default):focus:not([readonly]) + label,
  input[type=time]:not(.browser-default):focus:not([readonly]) + label,
  input[type=date]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
  input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
  input[type=number]:not(.browser-default):focus:not([readonly]) + label,
  input[type=search]:not(.browser-default):focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #fbc02d;
  }
  .input-field .prefix.active {
    color: #fbc02d;
  }

  [type="checkbox"]:checked + span:not(.lever)::before {

    border-right: 2px solid #fbc02d;
    border-bottom: 2px solid #fbc02d;
  }
</style>
